import { withoutHost } from 'ufo'

/**
 * Remove the "fr-ca" prefix from "fr-ca/slug" to make sure we're passing only the slug in certain cases"
*/
const unlocalizedUri = () => {
    const { activeSite } = useSharedState()
    const route = useRoute()
    const pathDefault = route.path ?? '/'
    return pathDefault.replace(new RegExp(`^/${activeSite.value.prefix}`), '').replace(/^\//, '').replace(/\/$/, '')
}

const currentUri = () => {
    const route = useRoute()
    return route.path ?? '/'
}

const fixedUri = (str = '/') => {
    if (typeof str !== 'string' || str === '') return str

    let res = str
    if (str !== '#' && str.indexOf('#') > -1) {
        const split = str.split('#')
        let splitUrl = split[0].replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, '/$1/')

        if (splitUrl !== '/') {
            splitUrl = splitUrl.replace(/\/$/, '')
        }
        res = {
            path: withoutHost(splitUrl),
            hash: `#${split[1]}`,
        }
    }
    else {
        res = str.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, '/$1/')
        res = withoutHost(res)
    }

    return res
}

const detectSiteFromPath = (path = '', sites = {}) => {
    const primarySite = getPrimarySite(sites)
    let detectedSite = primarySite // set primary site by default
    if (path) {
        // remove slash at the begining and end
        const realPath = path.replace(/^\/|\/$/g, '')
        const siteKeys = Object.keys(sites)
        for (let i = 0, il = siteKeys.length; i < il; ++i) {
            const siteKey = siteKeys[i]
            const site = sites[siteKey]
            // ignore primary site, it is defaulted to
            if (!site.primary) {
                if (realPath.startsWith(site.prefix)) {
                    detectedSite = site
                }
            }
        }
    }
    // useState returns deeply reactive objects, we need the raw data
    return toRaw(detectedSite)
}

export {
    unlocalizedUri, fixedUri, detectSiteFromPath, currentUri,
}
