/**
 * Site configuration data.
 * @type {Object}
 */
const sitesData = {
    QC_fr_CA: {
        sort: 1,
        id: 3,
        name: 'Québec',
        lang: 'QC_fr_CA',
        locale: 'fr_CA',
        shortlang: 'fr',
        primary: false,
        prefix: 'qc/fr',
        metalang: 'fr-ca',
        groupId: 5,
        default: true,
    },
    QC_en_CA: {
        sort: 1,
        id: 3,
        name: 'Québec',
        lang: 'QC_en_CA',
        locale: 'en_CA',
        shortlang: 'en',
        primary: false,
        prefix: 'qc/en',
        metalang: 'en-ca',
        groupId: 5,
        default: false,
    },
    CA_en_CA: {
        sort: 2,
        id: 2,
        name: 'Canada',
        lang: 'CA_en_CA',
        locale: 'en_CA',
        shortlang: 'en',
        metalang: 'en-ca',
        primary: true,
        prefix: 'ca/en',
        groupId: 2,
        default: true,
    },
    CA_fr_CA: {
        sort: 2,
        id: 2,
        name: 'Canada',
        lang: 'CA_fr_CA',
        locale: 'fr_CA',
        shortlang: 'fr',
        metalang: 'fr-ca',
        primary: false,
        prefix: 'ca/fr',
        groupId: 2,
        default: false,
    },
    US_en_US: {
        sort: 3,
        id: 4,
        name: 'United States',
        lang: 'US_en_US',
        locale: 'en_US',
        shortlang: 'en',
        primary: false,
        prefix: 'us/en',
        metalang: 'en-us',
        groupId: 4,
        default: true,
    },
    INTL_en_GB: {
        sort: 4,
        id: 7,
        name: 'International',
        lang: 'INTL_en_GB',
        locale: 'en_GB',
        shortlang: 'en',
        primary: false,
        prefix: 'intl/en',
        metalang: 'en-gb',
        groupId: 3,
        default: true,
    },
    INTL_fr_FR: {
        sort: 5,
        id: 6,
        name: 'International',
        lang: 'INTL_fr_FR',
        locale: 'fr_FR',
        shortlang: 'fr',
        primary: false,
        prefix: 'intl/fr',
        metalang: 'fr-fr',
        groupId: 3,
        default: false,
    },
}

/**
 * Hook for managing shared state across the application.
 * @returns {Object} An object containing state variables and functions to manipulate them.
 */
const useSharedState = () => {
    // State variables
    const navs = useState('navs', () => [])
    const alternateUrls = useState('alternateUrls', () => [])
    const translations = useState('translations', () => ({}))
    const translationsSite = useState('translationsSite', () => ({}))
    const groups = useState('groups', () => [{
        id: 2,
        name: 'Canada',
    }])
    const countryBannerOpened = useState('countryBannerOpened', () => false)
    const sites = useState('sites', () => (sitesData))
    const activeSite = useState('activeSite', () => sites.value.en_CA)
    const currentEntry = useState('currentEntry', () => null)
    const globals = useState('globals', () => [])
    const globalsSite = useState('globalsSite', () => ({}))

    // Cookies
    const langCookie = useCookie('i18n_redirected')
    const langChosenCookie = useCookie('i18n_chosen')
    const olnuf = useCookie('olnuf', { default: () => false })

    const { setLocaleCookie } = useI18n()

    /**
     * Sets the language cookie.
     * @param {string} lang - The language code to set.
     */
    const setLangCookie = (lang) => {
        langCookie.value = lang
        refreshCookie('i18n_redirected')
    }

    /**
     * Sets the chosen language cookie.
     * @param {string} lang - The language code to set.
     */
    const setLangChosenCookie = (lang) => {
        langChosenCookie.value = lang
        refreshCookie('i18n_chosen')
    }

    /**
     * Sets the olnuf cookie.
     * @param {boolean} val - The value to set.
     */
    const setOlnuf = (val) => {
        if (isSEO()) {
            olnuf.value = true
        }
        else {
            olnuf.value = val
        }
        refreshCookie('olnuf')
    }

    /**
     * Retrieves a site by its meta language.
     * @param {string} metalang - The meta language to search for.
     * @returns {Object|null} The site object if found, null otherwise.
     */
    const getSiteByMetaLang = (metalang) => {
        let site = null
        const siteKeys = Object.keys(sites.value)
        for (let i = 0, il = siteKeys.length; i < il; ++i) {
            const siteKey = siteKeys[i]
            if (sites.value[siteKey].metalang === metalang) {
                site = sites.value[siteKey]
            }
        }
        return site
    }

    /**
     * Retrieves a site by its language.
     * @param {string} metalang - The meta language to search for.
     * @returns {Object|null} The site object if found, null otherwise.
     */
    const getSiteByLang = (siteLang) => {
        let site = null
        const siteKeys = Object.keys(sites.value)
        for (let i = 0, il = siteKeys.length; i < il; ++i) {
            const siteKey = siteKeys[i]
            if (sites.value[siteKey].lang === siteLang) {
                site = sites.value[siteKey]
            }
        }
        return site
    }

    /**
     * Switches the active site.
     * @param {string} siteLang - The meta language of the site to switch to.
     * @param {boolean} force - Whether to force navigation even if the site is already active.
     */
    const switchActiveSite = async (siteLang, force = false) => {
        setLangChosenCookie(siteLang)
        setLocaleCookie(siteLang)
        const site = getSiteByLang(siteLang)
        const siteHome = ('/' + site.prefix).replace(/\/?$/, '/')

        if (force === true) {
            navigateTo(siteHome)
        }
        else if (activeSite.value.lang !== siteLang) {
            navigateTo(siteHome)
        }
        countryBannerOpened.value = false
    }

    /**
     * Sets the navigation data.
     * @param {Array} navsData - The navigation data to set.
     */
    const setNavs = (navsData = []) => {
        navs.value = navsData
    }

    /**
     * Sets the global data and translations.
     * @param {Array} globalsData - The global data to set.
     */
    const setGlobals = (globalsData = []) => {
        globals.value = globalsData

        const res = {}
        if (globalsData?.translations?.ui) {
            for (let i = 0, il = globalsData?.translations.ui.length; i < il; ++i) {
                res[globalsData?.translations?.ui[i].label] = globalsData?.translations.ui[i].translation
            }
        }
        translations.value = res
    }

    /**
     * Sets the global data and translations.
     * @param {Array} globalsData - The global data to set.
     * @param {string} site - The site code.
     */
    const setGlobalsSite = (globalsData = [], site = null) => {
        if (site === null) {
            const primarySite = getPrimarySite(sitesData)
            site = primarySite.lang
        }
        globalsSite.value[site] = globalsData

        const res = {}
        if (globalsData?.translations?.ui) {
            for (let i = 0, il = globalsData?.translations.ui.length; i < il; ++i) {
                res[globalsData?.translations?.ui[i].label] = globalsData?.translations.ui[i].translation
            }
        }
        translationsSite.value[site] = res

        // console.log('globals for site', site, globalsData, globalsSite.value, translationsSite.value);
    }

    /**
     * Sets the alternate URLs.
     * @param {Array} alternateUrlsData - The alternate URL data to set.
     */
    const setAlternateUrls = (alternateUrlsData = []) => {
        const alts = toRaw(alternateUrlsData)
        const res = []
        for (let i = 0, il = alts.length; i < il; ++i) {
            const alternateUrl = alts[i]
            if (activeSite.value.groupId === sites.value[alternateUrl.siteHandle].groupId) {
                alternateUrl.fixed = alternateUrl.url
                res.push(toRaw(alternateUrl))
            }
        }
        alternateUrls.value = res
    }

    /**
     * Sets the groups data.
     * @param {Array} groupsData - The groups data to set.
     */
    const setGroups = (groupsData = []) => {
        groups.value = groupsData
    }

    /**
     * Sets the sites data.
     * @param {Object} sitesData - The sites data to set.
     */
    const setSites = (sitesData = {}) => {
        sites.value = sitesData
    }

    /**
     * Sets the active site.
     * @param {Object} activeSiteData - The active site data to set.
     */
    const setActiveSite = (activeSiteData = {}) => {
        activeSite.value = activeSiteData
    }

    /**
     * Sets the current entry and updates alternate URLs.
     * @param {Object} currentEntryData - The current entry data to set.
     */
    const setCurrentEntry = (currentEntryData = null) => {
        currentEntry.value = currentEntryData
        if (currentEntry.value?.localized) {
            setAlternateUrls(currentEntry.value.localized)
        }
    }

    /**
     * Sets the current SEO data.
     * @param {Object} seoData - The SEO data to set.
     */
    const setCurrentSeo = (seoData = null) => {
        if (seoData) {
            let metaTitleContainerFormatted = ''
            const metaTitleContainer = JSON.parse(seoData.metaTitleContainer)
            metaTitleContainerFormatted = metaTitleContainer?.title?.title

            let metaTagContainerFormatted = []
            const metaTagContainer = JSON.parse(seoData.metaTagContainer)
            const metaTagContainerKeys = Object.keys(metaTagContainer)
            for (let i = 0, il = metaTagContainerKeys.length; i < il; ++i) {
                let metaKey = metaTagContainerKeys[i]
                if (!Array.isArray(metaTagContainer[metaKey]) && Object.values(metaTagContainer[metaKey]).length > 0) {
                    metaTagContainerFormatted.push(metaTagContainer[metaKey])
                }
            }

            let metaLinkContainerFormatted = []
            const metaLinkContainer = JSON.parse(seoData.metaLinkContainer)
            const metaLinkContainerKeys = Object.keys(metaLinkContainer)
            for (let i = 0, il = metaLinkContainerKeys.length; i < il; ++i) {
                let metaKey = metaLinkContainerKeys[i]
                if (!Array.isArray(metaLinkContainer[metaKey]) && Object.values(metaLinkContainer[metaKey]).length > 0) {
                    metaLinkContainerFormatted.push(metaLinkContainer[metaKey])
                }
            }

            let metaScriptContainerFormatted = []
            const metaScriptContainer = JSON.parse(seoData.metaScriptContainer)
            const metaScriptContainerKeys = Object.keys(metaScriptContainer)
            for (let i = 0, il = metaScriptContainerKeys.length; i < il; ++i) {
                let metaKey = metaScriptContainerKeys[i]
                if (!Array.isArray(metaScriptContainer[metaKey]) && Object.values(metaScriptContainer[metaKey]).length > 0) {
                    metaScriptContainerFormatted.push(metaScriptContainer[metaKey])
                }
            }

            useHead({
                link: metaLinkContainerFormatted,
                meta: metaTagContainerFormatted,
                script: metaScriptContainerFormatted,
                title: metaTitleContainerFormatted,
            })
        }
    }

    /**
     * Translates a label.
     * @param {string} label - The label to translate.
     * @returns {string} The translated label or the original if not found.
     */
    const tt = (label = '') => {
        let res = ''
        if (label && typeof translations.value[label] !== 'undefined') {
            res = translations.value[label]
        }
        else {
            res = label
        }
        return res
    }

    /**
     * Computed property to determine if the country banner should be visible.
     * @type {ComputedRef<boolean>}
     */
    const countryBannerVisible = computed(() => {
        let res = true
        if (countryBannerOpened.value === true) {
            res = true
        }
        else if (langChosenCookie.value) {
            res = false
        }
        else if (isSEO()) {
            res = false
        }
        return res
    })

    return {
        langCookie,
        setLangCookie,
        langChosenCookie,
        setLangChosenCookie,
        olnuf,
        setOlnuf,
        countryBannerVisible,
        navs,
        globals,
        globalsSite,
        alternateUrls,
        sites,
        activeSite,
        groups,
        currentEntry,
        translations,
        translationsSite,
        setNavs,
        setGlobals,
        setGlobalsSite,
        setAlternateUrls,
        setGroups,
        setSites,
        setActiveSite,
        setCurrentEntry,
        tt,
        getSiteByMetaLang,
        switchActiveSite,
        countryBannerOpened,
        setCurrentSeo,
    }
}

export { useSharedState, sitesData }
