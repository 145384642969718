import VueGoogleMaps from '@fawmi/vue-google-maps'
import { detectSiteFromPath } from '@/utils/uris.js'
import { sitesData } from '@/composables/use-shared-state.js'

export default defineNuxtPlugin((nuxtApp) => {
    const currentSite = detectSiteFromPath(nuxtApp.payload.path, sitesData)

    nuxtApp.vueApp.use(VueGoogleMaps, {
        load: {
            language: currentSite.shortlang,
            key: 'AIzaSyBtOceqOYMiY1eLyuCNebB1cNs5DfbQiig',
        },
    })
})
