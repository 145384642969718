<script setup>
import { animate } from 'motion'

const {
    loadingShown,
} = useClientState()
const loaderPosition = ref({ x: 0, y: 0 })
const targetPosition = ref({ x: 0, y: 0 })
const isVisible = ref(false)
const showLoaderTimeout = ref(null)
const svg = ref(null)

/**
 * Updates the target position based on mouse movement
 * @param {MouseEvent} event - The mouse event object
 */
const updateTargetPosition = (event) => {
    targetPosition.value = {
        x: event.clientX + 12,
        y: event.clientY + 12,
    }
}

/**
 * Animates the loader position with a smooth, laggy effect
 */
const animatePosition = () => {
    loaderPosition.value = {
        x: loaderPosition.value.x + (targetPosition.value.x - loaderPosition.value.x) * 0.1,
        y: loaderPosition.value.y + (targetPosition.value.y - loaderPosition.value.y) * 0.1,
    }
    requestAnimationFrame(animatePosition)
}

/**
 * Watches loadingShown and updates isVisible with a delay
 */
watch(loadingShown, (newValue) => {
    // Clear any existing timeout to prevent race conditions
    if (showLoaderTimeout.value) {
        clearTimeout(showLoaderTimeout.value)
        showLoaderTimeout.value = null
    }

    if (newValue) {
        // Set a timeout to show the loader after some time
        showLoaderTimeout.value = setTimeout(() => {
            if (loadingShown.value) {
                isVisible.value = true
            }
        }, 450)
    }
    else {
        setTimeout(() => {
            isVisible.value = false
        }, 300) // Delay hiding to allow for transition
    }
})

onMounted(() => {
    if (!isTouch()) {
        window.addEventListener('mousemove', updateTargetPosition)
        animatePosition()
    }

    /**
     * animate-spin seems buggy and freezes when loading
     * certain pages under certain conditions
     * so let's use motion instead
     */
    animate(
        svg.value,
        {
            rotate: 360,
        },
        {
            duration: 2,
            repeat: Infinity,
            easing: 'linear',
        },
    )
})

onUnmounted(() => {
    if (!isTouch()) {
        window.removeEventListener('mousemove', updateTargetPosition)
    }
})
</script>

<template>
    <Transition
        enter-active-class="transition-opacity duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-300 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div

            v-show="isVisible"
            class="loader-cursor pointer-events-none fixed left-0 top-0 z-100 flex size-6 translate-x-[var(--loader-x)] translate-y-[var(--loader-y)] items-center justify-center rounded-full border border-black/5 bg-white shadow-lg will-change-transform can-touch:bottom-4 can-touch:left-auto can-touch:right-4 can-touch:top-auto"
            :style="{
                '--loader-x': `${loaderPosition.x}px`,
                '--loader-y': `${loaderPosition.y}px`,
            }"
        >
            <svg
                ref="svg"
                class="translate-z-0 backface-hidden size-4 text-midblue will-change-transform"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 12C20 7.58203 16.4183 4 12 4V0C18.6274 0 24 5.37305 24 12H20Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    </Transition>
</template>
