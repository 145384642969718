import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["FetchAllBrands","FetchDistributors","FetchFormieForm","FetchGlobals","FetchListNews","FetchLocalized","FetchNav","FetchRelatedBrands","FetchRelatedProducts","FetchSingleNew","FetchSliderNews","FlexibleBrand","FlexiblePage","FlexibleFields","FormieField","FormieForm"]}
export const GqlFetchAllBrands = (...params) => useGql()('FetchAllBrands', ...params)
export const GqlFetchDistributors = (...params) => useGql()('FetchDistributors', ...params)
export const GqlFetchFormieForm = (...params) => useGql()('FetchFormieForm', ...params)
export const GqlFetchGlobals = (...params) => useGql()('FetchGlobals', ...params)
export const GqlFetchListNews = (...params) => useGql()('FetchListNews', ...params)
export const GqlFetchLocalized = (...params) => useGql()('FetchLocalized', ...params)
export const GqlFetchNav = (...params) => useGql()('FetchNav', ...params)
export const GqlFetchRelatedBrands = (...params) => useGql()('FetchRelatedBrands', ...params)
export const GqlFetchRelatedProducts = (...params) => useGql()('FetchRelatedProducts', ...params)
export const GqlFetchSingleNew = (...params) => useGql()('FetchSingleNew', ...params)
export const GqlFetchSliderNews = (...params) => useGql()('FetchSliderNews', ...params)
export const GqlFlexibleBrand = (...params) => useGql()('FlexibleBrand', ...params)
export const GqlFlexiblePage = (...params) => useGql()('FlexiblePage', ...params)