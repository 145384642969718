const getOffset = () => {
    const nav = document.querySelector('.nav-main')
    const navSecondary = document.querySelector('.nav-secondary')
    const countrySelector = document.querySelector('.country-selector')
    const navHeight = nav ? nav.getBoundingClientRect().height : 0
    const navSecondaryHeight = navSecondary ? navSecondary.getBoundingClientRect().height : 0
    const countrySelectorHeight = countrySelector ? countrySelector.getBoundingClientRect().height : 0
    // Handle offset on ly if the target is a number
    // if (typeof target === 'number') {
    //     let offset = 0;

    //     if (target <= 300) {
    //         console.log('high');
    //         offset = 247; // Nav should be enlarged and opened when getting there, offset needs to be larger
    //     } else if ((target + 71) <= lenis.value.scroll) {
    //         console.log('smaller');
    //         offset = 71; // Nav should be visible when getting there, but meta-nav hidden, offset needs to be smaller
    //     } else {
    //         console.log('larger');
    //         offset = 91; // Nav should be visible when getting there with meta-nav visible, offset needs to be larger
    //     }

    //     return (offset + countrySelectorHeight) * -1;
    // }
    return (navHeight + navSecondaryHeight + countrySelectorHeight) * -1
}

const lenisScrollTo = (target, options = {}) => {
    // const router = useRouter();
    if (target) {
        let realTarget = target
        // Fix for "sticky" positioned elements
        if (target.dataset.scrollTop) {
            realTarget = parseInt(target.dataset.scrollTop, 10)
        }

        lenis.value.scrollTo(realTarget, {
            offset: getOffset(realTarget),
            duration: 2.5,
            ...options,
        })
    }
}

export { lenisScrollTo }
