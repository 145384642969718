import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFormieAlert, LazyFormieField, LazyFormieForm, LazyFormieInputAddress, LazyFormieInputAgree, LazyFormieInputCategories, LazyFormieInputCheckboxes, LazyFormieInputDate, LazyFormieInputDropdown, LazyFormieInputEmail, LazyFormieInputEntries, LazyFormieInputFileUpload, LazyFormieInputGroup, LazyFormieInputHeading, LazyFormieInputHidden, LazyFormieInputHtml, LazyFormieInputMultiLineText, LazyFormieInputName, LazyFormieInputNumber, LazyFormieInputPhone, LazyFormieInputRadio, LazyFormieInputRecipients, LazyFormieInputRepeater, LazyFormieInputSection, LazyFormieInputSingleLineText, LazyFormieInputTable, LazyFormieInputTags, LazyFormieInputUsers, LazyFormieInstructions, LazyFormieLabel, LazyFormieLanding, LazyFormiePage, LazyFlexibleAccordions, LazyFlexibleCircleContent, LazyFlexibleDiscoverProducts, LazyFlexibleDoubleSliderImages, LazyFlexibleEventList, LazyFlexible, LazyFlexibleForm, LazyFlexibleIconTitleText, LazyFlexibleImageFull, LazyFlexibleImageGrid, LazyFlexibleImageLink, LazyFlexibleJobs, LazyFlexibleMaps, LazyFlexibleNewsList, LazyFlexiblePanelsBrands, LazyFlexiblePanels, LazyFlexibleProductBrandsFlatList, LazyFlexibleProductBrandsList, LazyFlexibleProductInfos, LazyFlexibleProductStats, LazyFlexibleProductVariant, LazyFlexibleSliderImages, LazyFlexibleSliderNews, LazyFlexibleTeams, LazyFlexibleTextContent, LazyFlexibleTextImage, LazyFlexibleTextTwoColumns, LazyFlexibleTitleSubtitle, LazyFlexibleTitleText, LazyFlexibleTwoColumns, LazyFlexibleVideo } from '#components'
const lazyGlobalComponents = [
  ["FormieAlert", LazyFormieAlert],
["FormieField", LazyFormieField],
["FormieForm", LazyFormieForm],
["FormieInputAddress", LazyFormieInputAddress],
["FormieInputAgree", LazyFormieInputAgree],
["FormieInputCategories", LazyFormieInputCategories],
["FormieInputCheckboxes", LazyFormieInputCheckboxes],
["FormieInputDate", LazyFormieInputDate],
["FormieInputDropdown", LazyFormieInputDropdown],
["FormieInputEmail", LazyFormieInputEmail],
["FormieInputEntries", LazyFormieInputEntries],
["FormieInputFileUpload", LazyFormieInputFileUpload],
["FormieInputGroup", LazyFormieInputGroup],
["FormieInputHeading", LazyFormieInputHeading],
["FormieInputHidden", LazyFormieInputHidden],
["FormieInputHtml", LazyFormieInputHtml],
["FormieInputMultiLineText", LazyFormieInputMultiLineText],
["FormieInputName", LazyFormieInputName],
["FormieInputNumber", LazyFormieInputNumber],
["FormieInputPhone", LazyFormieInputPhone],
["FormieInputRadio", LazyFormieInputRadio],
["FormieInputRecipients", LazyFormieInputRecipients],
["FormieInputRepeater", LazyFormieInputRepeater],
["FormieInputSection", LazyFormieInputSection],
["FormieInputSingleLineText", LazyFormieInputSingleLineText],
["FormieInputTable", LazyFormieInputTable],
["FormieInputTags", LazyFormieInputTags],
["FormieInputUsers", LazyFormieInputUsers],
["FormieInstructions", LazyFormieInstructions],
["FormieLabel", LazyFormieLabel],
["FormieLanding", LazyFormieLanding],
["FormiePage", LazyFormiePage],
["FlexibleAccordions", LazyFlexibleAccordions],
["FlexibleCircleContent", LazyFlexibleCircleContent],
["FlexibleDiscoverProducts", LazyFlexibleDiscoverProducts],
["FlexibleDoubleSliderImages", LazyFlexibleDoubleSliderImages],
["FlexibleEventList", LazyFlexibleEventList],
["Flexible", LazyFlexible],
["FlexibleForm", LazyFlexibleForm],
["FlexibleIconTitleText", LazyFlexibleIconTitleText],
["FlexibleImageFull", LazyFlexibleImageFull],
["FlexibleImageGrid", LazyFlexibleImageGrid],
["FlexibleImageLink", LazyFlexibleImageLink],
["FlexibleJobs", LazyFlexibleJobs],
["FlexibleMaps", LazyFlexibleMaps],
["FlexibleNewsList", LazyFlexibleNewsList],
["FlexiblePanelsBrands", LazyFlexiblePanelsBrands],
["FlexiblePanels", LazyFlexiblePanels],
["FlexibleProductBrandsFlatList", LazyFlexibleProductBrandsFlatList],
["FlexibleProductBrandsList", LazyFlexibleProductBrandsList],
["FlexibleProductInfos", LazyFlexibleProductInfos],
["FlexibleProductStats", LazyFlexibleProductStats],
["FlexibleProductVariant", LazyFlexibleProductVariant],
["FlexibleSliderImages", LazyFlexibleSliderImages],
["FlexibleSliderNews", LazyFlexibleSliderNews],
["FlexibleTeams", LazyFlexibleTeams],
["FlexibleTextContent", LazyFlexibleTextContent],
["FlexibleTextImage", LazyFlexibleTextImage],
["FlexibleTextTwoColumns", LazyFlexibleTextTwoColumns],
["FlexibleTitleSubtitle", LazyFlexibleTitleSubtitle],
["FlexibleTitleText", LazyFlexibleTitleText],
["FlexibleTwoColumns", LazyFlexibleTwoColumns],
["FlexibleVideo", LazyFlexibleVideo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
