import { ref } from 'vue'
import Lenis from 'lenis'
import Tempus from '@darkroom.engineering/tempus'
import { gsap } from '~/composables/use-gsap.js'
// import { gsap, ScrollTrigger } from '~/composables/use-gsap.js';
// const route = useRoute();

const lenis = ref(null)
const metaKey = ref(false)

/**
 * Function to prevent scrolling inside Google Maps (with meta key pressed to zoom in)
 */
const checkPrevent = (node) => {
    if (node.hasAttribute('data-lenis-prevent-meta') && metaKey.value) {
        return true
    }
    return false
}

const useLenis = (options = { lerp: 0.05, prevent: checkPrevent }) => {
    if (!lenis.value) {
        if (!isServer()) {
            ['keydown', 'keyup', 'keypress'].forEach((event) => {
                window.addEventListener(event, (ev) => {
                    metaKey.value = ev.metaKey
                })
            })

            // if (!isServer() && !route?.query?.token && !route?.query?.['x-craft-live-preview']) {
            lenis.value = new Lenis(options)
            // lenis.value.on('scroll', () => {
            //     ScrollTrigger.update();
            // });
            gsap.ticker.remove(gsap.updateRoot)
            Tempus.add((time) => {
                gsap.updateRoot(time / 1000)
                lenis.value.raf(time)
            }, 999)
        }
    }

    return lenis
}

export { useLenis, lenis }
