import { default as indexdImrn95LHgMeta } from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93H3QnsDtcCPMeta } from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue?macro=true";
import { default as _91slug_93szkQuHxAGFMeta } from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue?macro=true";
import { default as indexWk7jwkKSguMeta } from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue?macro=true";
import { default as _91slug_93RoRJ82kA3QMeta } from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "slug___ca/fr",
    path: "/ca/fr/:slug(.*)*/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue")
  },
  {
    name: "slug___ca/en",
    path: "/ca/en/:slug(.*)*/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue")
  },
  {
    name: "slug___qc/fr",
    path: "/qc/fr/:slug(.*)*/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue")
  },
  {
    name: "slug___qc/en",
    path: "/qc/en/:slug(.*)*/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue")
  },
  {
    name: "slug___intl/en",
    path: "/intl/en/:slug(.*)*/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue")
  },
  {
    name: "slug___intl/fr",
    path: "/intl/fr/:slug(.*)*/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue")
  },
  {
    name: "slug___us/en",
    path: "/us/en/:slug(.*)*/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/[...slug].vue")
  },
  {
    name: "index___ca/fr",
    path: "/ca/fr/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "index___ca/en",
    path: "/ca/en/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "index___qc/fr",
    path: "/qc/fr/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "index___qc/en",
    path: "/qc/en/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "index___intl/en",
    path: "/intl/en/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "index___intl/fr",
    path: "/intl/fr/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "index___us/en",
    path: "/us/en/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/index.vue")
  },
  {
    name: "news-slug___ca/fr",
    path: "/ca/fr/nouvelles/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue")
  },
  {
    name: "news-slug___ca/en",
    path: "/ca/en/news/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue")
  },
  {
    name: "news-slug___qc/fr",
    path: "/qc/fr/nouvelles/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue")
  },
  {
    name: "news-slug___qc/en",
    path: "/qc/en/news/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue")
  },
  {
    name: "news-slug___intl/en",
    path: "/intl/en/news/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue")
  },
  {
    name: "news-slug___intl/fr",
    path: "/intl/fr/nouvelles/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue")
  },
  {
    name: "news-slug___us/en",
    path: "/us/en/news/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/[slug].vue")
  },
  {
    name: "news___ca/fr",
    path: "/ca/fr/nouvelles/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue")
  },
  {
    name: "news___ca/en",
    path: "/ca/en/news/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue")
  },
  {
    name: "news___qc/fr",
    path: "/qc/fr/nouvelles/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue")
  },
  {
    name: "news___qc/en",
    path: "/qc/en/news/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue")
  },
  {
    name: "news___intl/en",
    path: "/intl/en/news/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue")
  },
  {
    name: "news___intl/fr",
    path: "/intl/fr/nouvelles/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue")
  },
  {
    name: "news___us/en",
    path: "/us/en/news/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/news/index.vue")
  },
  {
    name: "our-brands-slug___ca/fr",
    path: "/ca/fr/nos-marques/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___ca/en",
    path: "/ca/en/our-brands/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___qc/fr",
    path: "/qc/fr/nos-marques/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___qc/en",
    path: "/qc/en/our-brands/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___intl/en",
    path: "/intl/en/our-brands/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___intl/fr",
    path: "/intl/fr/nos-marques/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___us/en",
    path: "/us/en/our-brands/:slug()/",
    component: () => import("/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/pages/our-brands/[slug].vue")
  }
]