import revive_payload_client_4sVQNw7RlN from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_jBToDIZpFa from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import graphql_error_catcher_BQ0d3151Ft from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/plugins/graphql-error-catcher.js";
import iframe_resizer_q8frHkFCIz from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/plugins/iframe-resizer.js";
import initial_load_1NTICydksi from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/plugins/initial-load.js";
import maps_qDol7Yp8c0 from "/Users/dee/Projets/Alezane - François Robert/Geloso/04_final/geloso.test/plugins/maps.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_jBToDIZpFa,
  plugin_ghbUAjaD3n,
  graphql_error_catcher_BQ0d3151Ft,
  iframe_resizer_q8frHkFCIz,
  initial_load_1NTICydksi,
  maps_qDol7Yp8c0
]