/**
 * Simple SEO bots detection to automatically remove the age gate
 */
export function isSEO() {
    let res = false
    let userAgent = ''
    if (isServer()) {
        const headers = useRequestHeaders(['user-agent'])
        userAgent = headers['user-agent']
    }
    else {
        userAgent = window.navigator.userAgent
    }
    res = /Lighthouse|Page Speed|GoogleBot|bot|crawl|slurp|spider|mediapartners|GTmetrix/.test(userAgent)
    return res
}
