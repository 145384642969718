export default function getPrimarySite(sites) {
    let primarySite = null
    const siteKeys = Object.keys(sites)
    for (let i = 0, il = siteKeys.length; i < il; ++i) {
        const siteKey = siteKeys[i]
        if (sites[siteKey].primary === true) {
            primarySite = sites[siteKey]
        }
    }
    return primarySite
}
