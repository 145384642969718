function makeDefaultPromise(to, from, savedPosition) {
    const nuxtApp = useNuxtApp()
    return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', () => {
            setTimeout(() => {
                const targetElement = to.hash ? document.querySelector(to.hash) : null
                if (savedPosition) {
                    resolve(savedPosition)
                    // resolve({ top: 0 });
                }
                else if (to.hash) {
                    // resolve({ el: to.hash });
                    lenisScrollTo(targetElement, {
                        onComplete: resolve({ el: to.hash }),
                    })
                }
                else {
                    resolve({ top: 0 })
                }
            }, 1200) // 1195ms = duration of page transition in app.vue so that the swap of content is not visible
        })
    })
}

export default {
    scrollBehavior(to, from, savedPosition) {
        if ((to.path === from.path && to.hash) || (savedPosition && to.hash)) {
            const targetElement = to.hash ? document.querySelector(to.hash) : null
            return new Promise((next) => {
                lenisScrollTo(targetElement, {
                    onComplete: next,
                })
            })
        }
        return makeDefaultPromise(to, from, savedPosition)
    },
}
